import React from 'react';

const FeaturesSection = () => (
  <section id="features" className="wrapper style5 special">
    <div className="inner">
      <header className="major">
        <h2>Main Features</h2>
        <p>
          Household grocery management directly in the smartphone. <br /> Always
          know what is available, what is about to expire and what is missing.
        </p>
      </header>
      <ul className="features">
        <li className="icon solid fa-boxes">
          <h3>Inventory Visualizations</h3>
          <p>
            Get a clear picture through a quick glance. Green is good. Orange
            expires soon. Red needs to be used immediately.
          </p>
        </li>
        <li className="icon solid fa-exclamation-triangle">
          <h3>Automatic Expiry Notifications</h3>
          <p>
            Proactive notifications before a given product expires. Use what is
            available before throwing it away.
          </p>
        </li>
        <li className="icon solid fa-receipt">
          <h3>Easy Inventory Import</h3>
          <p>
            Add new items through the built in shopping list or scan the
            supermarket receipt after purchase. All through a few clicks.
          </p>
        </li>
        <li className="icon solid fa-check-square">
          <h3>Consumption Tracking</h3>
          <p>
            Easily track what has been consumed using a smartphone or the voice
            assistant. &quot;Hey Siri, I took a glass of milk and an
            apple.&quot;
          </p>
        </li>
        <li className="icon solid fa-info-circle">
          <h3>Nutritional Information</h3>
          <p>
            Get nutritional information about products. Track nutrition through
            food consumption.
          </p>
        </li>
        <li className="icon solid fa-shopping-bag">
          <h3>Food Storage Suggestions</h3>
          <p>
            Be informed about best storage practices for storing in order to
            prolong the lifetime of the items.
          </p>
        </li>
        <li className="icon solid fa-shopping-cart">
          <h3>Shopping List</h3>
          <p>
            Create shopping lists directly in the app to easily add items to the
            inventory during shopping.
          </p>
        </li>
        <li className="icon solid fa-coins">
          <h3>Savings Reports</h3>
          <p>See food waste reduction and savings over time.</p>
        </li>
      </ul>
    </div>
  </section>
);

export default FeaturesSection;
