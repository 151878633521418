import React from 'react';

import signupStyles from './MailChimpSignUp.module.css';

const MailChimpSignUp = () => (
  <div id={signupStyles.mcEmbedSignup}>
    <form
      action="https://smartpantry.us4.list-manage.com/subscribe/post?u=28408d3ea651e6fe348f1d7cd&amp;id=06e850e7c7"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      className="validate"
      target="_blank"
      noValidate
    >
      <div id="mc_embed_signup_scroll">
        <h3>Subscribe to get news</h3>
        {/* <div className="indicates-required">
          <span className="asterisk">*</span> indicates required
        </div> */}
        <div className="mc-field-group">
          {/* <label htmlFor="mce-EMAIL">
            Email Address <span className="asterisk">*</span>
          </label> */}
          <input
            type="email"
            name="EMAIL"
            placeholder="Email"
            className="required email"
            id="mce-EMAIL"
          />
          <div id="mce-responses" className="clear">
            <div
              className="response"
              id="mce-error-response"
              style={{ display: 'none' }}
            />
            <div
              className="response"
              id="mce-success-response"
              style={{ display: 'none' }}
            />
          </div>
          {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
        </div>
        <div
          style={{ position: 'absolute', left: '-5000px' }}
          aria-hidden="true"
        >
          <input
            type="text"
            name="b_28408d3ea651e6fe348f1d7cd_06e850e7c7"
            tabIndex={-1}
            value=""
            readOnly={true}
          />
        </div>
        <div className={signupStyles.clear}>
          <input
            type="submit"
            value="Subscribe"
            name="subscribe"
            id={signupStyles.mcEmbeddedSubscribe}
            className="button"
          />
        </div>
      </div>
    </form>
  </div>
);

export default MailChimpSignUp;
