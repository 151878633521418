import React from 'react';

import teamStyles from './team.module.css';
import { TeamMemberData } from './Team';

interface Props {
  member: TeamMemberData;
}

const TeamMember: React.FC<Props> = props => {
  const { firstName, lastName, role, background, picture } = props.member;
  const style = { gridArea: firstName.toLowerCase() };
  return (
    <div className={teamStyles.member} style={style}>
      <img
        src={picture}
        className={teamStyles.image}
        alt={`${firstName} ${lastName}`}
      />
      <h3 className={teamStyles.name}>
        {firstName} {lastName}
      </h3>
      <p className={teamStyles.role}>{role}</p>
      <p className={teamStyles.background}>{background}</p>
    </div>
  );
};

export default TeamMember;
