import React from 'react';

const AboutSection = () => (
  <section id="about" className="wrapper style5 special">
    <div className="inner">
      <header className="major">
        <h2>About</h2>
        <p>
          SmartPantry is a Swiss startup creating an innovative virtual kitchen
          assistant app aiming to help families reduce food waste.
        </p>
        <p>
          According to the{' '}
          <a
            href="https://www.bafu.admin.ch/bafu/en/home/topics/waste/guide-to-waste-a-z/biodegradable-waste/types-of-waste/lebensmittelabfaelle.html#-1928793991"
            target="_blank"
            rel="noopener noreferrer"
          >
            Federal Office for the Environment
          </a>{' '}
          in Switzerland, every year a total of 494’000 tonnes of food is
          unnecessarily wasted in households in Switzerland alone. In addition
          to unnecessary financial expenses, food waste also has a major impact
          on the environment.
        </p>
        <p>
          SmartPantry is currently supported by JUGEN Food SA and incubated at{' '}
          <a
            href="https://www.cpstartup.ch/website/en"
            target="_blank"
            rel="noopener noreferrer"
          >
            CP Startup
          </a>
          . In 2019 the team reached the finals in the startup challenge{' '}
          <a
            href="https://boldbrain.ch"
            target="_blank"
            rel="noopener noreferrer"
          >
            BoldBrain
          </a>
          . In the spring 2020 SmartPantry received credits from{' '}
          <a
            href="https://www.innosuisse.ch/inno/en/home.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Innosuisse
          </a>{' '}
          in the form of an Innocheque to realize a preliminary R&D study in
          collaboration with{' '}
          <a
            href="https://codelounge.si.usi.ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            CodeLounge
          </a>{' '}
          (Software Institute, USI).
        </p>
      </header>
      <ul className="icons major">
        <li>
          <span className="icon solid fa-mobile-alt major style1">
            <span className="label">Smartphone</span>
          </span>
        </li>
        <li>
          <span className="icon solid fa-leaf major style2">
            <span className="label">Environment</span>
          </span>
        </li>
        <li>
          <span className="icon solid fa-coins major style3">
            <span className="label">Economy</span>
          </span>
        </li>
      </ul>
    </div>
  </section>
);

export default AboutSection;
