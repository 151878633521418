import React from 'react';

import teamStyles from './team.module.css';
import TeamMemberData from './TeamMember';

import alec from '../../assets/images/team/alec-bernasconi.jpg';
// import anastasia from '../../assets/images/team/anastasia-stanga.jpg';
import giacomo from '../../assets/images/team/giacomo-imperato.jpg';
import gianfranco from '../../assets/images/team/gianfranco-imperato.jpg';
import giovanna from '../../assets/images/team/giovanna-pedroni.jpg';
import jesper from '../../assets/images/team/jesper-findahl.jpg';
// import nicoletta from '../../assets/images/team/nicoletta-maestrini.jpg';
import jenny from '../../assets/images/team/jenny-findahl.jpeg';
// import unknownPerson from '../../assets/images/team/unknown-person.png';
import sara from '../../assets/images/team/sara-mangialavori.jpg';
import TeamMember from './TeamMember';

export interface TeamMemberData {
  firstName: string;
  lastName: string;
  role: string;
  background: string;
  picture: string;
}

const members: TeamMemberData[] = [
  {
    firstName: 'Giacomo',
    lastName: 'Imperato',
    role: 'Co-Founder and CEO',
    background:
      'Holds a Bachelor in Economics from Università della Svizzera italiana (USI) in Lugano',
    picture: giacomo
  },
  {
    firstName: 'Jesper',
    lastName: 'Findahl',
    role: 'Co-Founder and CTO',
    background:
      'Junior R&D Software Engineer at CodeLounge (USI), holds a Master in Informatics from USI',
    picture: jesper
  },
  {
    firstName: 'Alec',
    lastName: 'Bernasconi',
    role: 'Co-Founder',
    background:
      'Holds a Bachelor in Economics from Università della Svizzera italiana (USI) in Lugano',
    picture: alec
  },
  {
    firstName: 'Gianfranco',
    lastName: 'Imperato',
    role: 'Legal and Finance Manager',
    background: 'CEO at Trasteel, holds an MBA from INSEAD in Fontainebleau',
    picture: gianfranco
  },
  {
    firstName: 'Giovanna',
    lastName: 'Pedroni',
    role: 'Nutrition and Food Expert',
    background: 'Studies Health Sciences and Technology at ETH Zurich',
    picture: giovanna
  },
  {
    firstName: 'Jenny',
    lastName: 'Findahl',
    role: 'Graphic Design',
    background: 'Freelance graphic designer and illustrator since 2001',
    picture: jenny
  },
  {
    firstName: 'Sara',
    lastName: 'Mangialavori',
    role: 'Software Developer',
    background: 'Studies Software & Data Engineering (Master) at USI, Lugano',
    picture: sara
  }
  // {
  //   firstName: 'Nicoletta',
  //   lastName: 'Maestrini',
  //   role: 'Responsible for Content and Communication',
  //   background:
  //     'Holds a Master in Communication Sciences from Università della Svizzera italiana (USI)',
  //   picture: nicoletta
  // },
  // {
  //   firstName: 'Anastasia',
  //   lastName: 'Stanga',
  //   role: 'Nutrition and Food Expert',
  //   background: 'Graduated in Food Engineering from ETH Zurich',
  //   picture: anastasia
  // },
];

const Team = () => (
  <div className={teamStyles.team}>
    {members.map((member, index) => (
      <TeamMember key={index} member={member} />
    ))}
  </div>
);

export default Team;
