import React from 'react';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import config from '../../config';
import AboutSection from '../sections/About';
import GoalsSection from '../sections/Goals';
import FeaturesSection from '../sections/Features';
import TeamSection from '../sections/TeamSection';

import appLogo from '../assets/images/SmartPantry-squirrel-1200x1200-191020.png';

import indexStyles from './index.module.css';
import MailChimpSignUp from '../components/MailChimpSignUp';

const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
        <div id={indexStyles.appDetailsContainer}>
          <div id={indexStyles.appLogoContainer}>
            <img src={appLogo} alt="SmartPantry app logo" />
          </div>
        </div>
        <div>
          <MailChimpSignUp />
        </div>
      </div>
      <Scroll type="id" element="about">
        <a href="#one" className="more">
          Learn More
        </a>
      </Scroll>
    </section>

    <AboutSection />
    <GoalsSection />
    <FeaturesSection />
    <TeamSection />
  </Layout>
);

export default IndexPage;
