import React from 'react';

import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';

const GoalsSection = () => (
  <section id="goals" className="wrapper alt style2">
    <section id="one" className="wrapper style2 special">
      <header className="major">
        <h2>Goals</h2>
      </header>
    </section>
    <section className="spotlight">
      <div className="image">
        <img src={pic1} alt="" />
      </div>
      <div className="content">
        <h2>Simple Platform</h2>
        <p>
          An easy to use smartphone app to track purchase and use of household
          groceries for families, shared households and individuals.
        </p>
      </div>
    </section>
    <section className="spotlight">
      <div className="image">
        <img src={pic2} alt="" />
      </div>
      <div className="content">
        <h2>Reduce Food Waste</h2>
        <p>
          Using food consumption tracking and algorithms the kitchen assistant
          can help the user to reduce food waste and thereby help save the
          world&apos;s resources.
        </p>
      </div>
    </section>
    <section className="spotlight">
      <div className="image">
        <img src={pic3} alt="" />
      </div>
      <div className="content">
        <h2>Increase Awareness</h2>
        <p>
          Through information on shelf life, storage, nutrition and
          environmental impact the user can easily understand and increase his
          or her awareness around food.
        </p>
      </div>
    </section>
  </section>
);

export default GoalsSection;
