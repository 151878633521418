import React from 'react';

import Team from '../components/team/Team';

const TeamSection = () => (
  <section id="team" className="wrapper style2">
    <div className="inner">
      <header className="major align-center">
        <h2>Team</h2>
      </header>
      <Team />
    </div>
  </section>
);

export default TeamSection;
